import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import {
  PageNav,
  AltPanels,
} from 'layouts';
import {
  Banner,
  Breadcrumbs,
} from 'components';
import { PAGES, BASE_URL } from 'config';

const BREADCRUMBS = [
  {
    name: PAGES.ABOUT.TITLE,
    absolute_slug: PAGES.ABOUT_AWARDS.PATH,
  },
  {
    name: PAGES.ABOUT_AWARDS.TITLE,
    absolute_slug: PAGES.ABOUT_AWARDS.PATH,
  },
];

const AboutAwardsPage = () => (
  <Layout page="about/our-awards">

    <Helmet
      title="Best New Zealand Tour Companies | Top Rated | Haka Tours"
      meta={[
        {
          name: 'description', content: 'Haka Tours has continued to have top rated small group tours to New Zealand over the years. We’re committed to offering guests the best New Zealand tours.',
        },
        { name: 'og:title', content: 'Best New Zealand Tour Companies | Top Rated | Haka Tours' },
        {
          name: 'og:description', content: 'Haka Tours has continued to have top rated small group tours to New Zealand over the years. We’re committed to offering guests the best New Zealand tours.',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/about-us/our-awards/` }]}
    />

    <Banner backgroundImage="https://hakatours-staging.imgix.net/000/our-awards-banner.jpg?fit=crop&auto=format">
      <h1 className="c-heading c-heading--h0">The Best New Zealand Tours</h1>
    </Banner>
    <PageNav pages={[PAGES.ABOUT_TRIPS, PAGES.ABOUT_STORY, PAGES.ABOUT_PURPOSE, PAGES.ABOUT_PEOPLE, PAGES.ABOUT_AWARDS, PAGES.ABOUT_FAQS, PAGES.ABOUT_REVIEWS]} />
    <section className="l-section">
      <div className="l-container">
        <Breadcrumbs list={BREADCRUMBS} />
      </div>
      <div className="l-container l-container--small u-text--center">
        <p>
          From our early years until now, Haka Tours continues to win highly sought after industry awards, often beating out much bigger competitors.
          Being one of the best New Zealand tour companies, offering top rated small group tours to New Zealand is a position which we at Haka Tours strive to uphold.
          These awards reflect our commitment to you, our guest, as well as the passion each of our team has for world class tours of New Zealand. Here are our most recent accolades.
        </p>
      </div>
    </section>
    <AltPanels items={[
      {
        title: 'Qualmark Gold Award',
        content: `<p>Haka Tours has once again been awarded Gold under the Sustainable Tourism Business criteria by Qualmark for 2023.
        Qualmark is an independent and official quality assurance organisation in New Zealand that ensures operators provide a quality and safe experience.</p>`,
        image: {
          width: 452,
          height: 218,
          title: 'Example image title',
          url: 'https://hakatours-staging.imgix.net/5d3e2fde6dd35b000112e017/Qualmark_Gold_Award_Logo_Stacked_original.jpg?fit=crop&auto=format',
          align: '',
        },
      },
      {
        title: '2019: ADVENTURE TOURISM AWARDS, CULTURAL TOURISM NZ WINNER',
        content: `<p>We are one hundred percent stoked to have won the Adventure Tourism Awards’ inaugural ‘Cultural Tourism’ award for New Zealand.
        We are committed to sharing and celebrating New Zealand culture across everything we do, that’s why we include cultural experiences in all of our tours and have our own cultural advisor, Eru.
        The purpose of his role is to enhance the cultural capability of our all staff and to ensure our tours and services are an example of cultural best practice. </p>`,
        image: {
          width: 452,
          height: 218,
          title: 'Example image title',
          url: 'https://static-hakatours.imgix.net/Award_2019.jpg?fit=crop&auto=format',
          align: '',
        },
      },
      {
        title: '2019: DELOITTE FAST 50 WINNER',
        content: `<p>It was amazing to learn we are New Zealand’s 21st fastest-growing business and the number one fastest-growing tourism business this year!
        This award is great recognition of our efforts to welcome over 1 million manuhiri (guests) by 2025 across our all of our tour and accommodation brands.
        Even with our industry leading annual growth, we are committed to upholding our world class NPS score of 82!</p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://static-hakatours.imgix.net/2019-Fastest-growing-companies.jpg?fit=crop&auto=format',
          align: '',
        },
      },
      {
        title: '2019: EXPORTNZ AWARDS, SUPREME & CATEGORY WINNER',
        content: `<p>We were blown away to receive this award which celebrates the success of New Zealand businesses on the world stage.
        We're proud that we were recognised for our professional excellence, innovative practice and leadership through vision, commitment and success.</p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://static-hakatours.imgix.net/Award_6.jpg?fit=crop&auto=format',
        },
      },
      {
        title: '2018: Adventure Tourism Awards, NZ’s Best Tour or Activity Winner',
        content: `<p>We're delighted to have won the award for New Zealand's Best Tour/Activity at Australasia's largest tourism awards!
        We were up against some amazing competition and were thrilled to be recognised for our world-class customer service and running the best guided tours of New Zealand!</p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://static-hakatours.imgix.net/Award_1.jpg?fit=crop&auto=format',
          align: '',
        },
      },
      {
        title: '2017: New Zealand Tourism Awards Supreme Winner',
        content: `<p>One awesome night, four epic awards! We won the Business Excellence Award for Large Tourism Businesses (1) Tourism Marketing Award (2) Our Founder,
        Ryan Sanders, won the Tourism Champion Award (3) and best of all - the overall Supreme Award (4)! Yes, We’re still pinching ourselves.</p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://static-hakatours.imgix.net/Award_5.jpg?fit=crop&auto=format',
          align: '',
        },
      },
      {
        title: '2018: Canterbury Business Awards, Emerging Leader Award',
        content: `<p>Proud to have our founder, Ryan Sanders, named as Canterbury's Emerging Leader at the Canterbury Business Awards.
        Ryan was born and bred in Canterbury (Christchurch) and it’s where Haka Tours first started, so it has a very special place in our hearts.</p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://static-hakatours.imgix.net/Award_3.jpg?fit=crop&auto=format',
          align: '',
        },
      },
      {
        title: '2018: Auckland Business Awards, Marketing Winner',
        content: `<p>We were proud dual finalists in the Auckland Business Awards in Customer Service Delivery and Marketing and to win the Excellence in Marketing Award.
        As a tourism brand, we are always super stoked when we are recognized for our marketing efforts too.</p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://static-hakatours.imgix.net/Award_4.jpg?fit=crop&auto=format',
          align: '',
        },
      },
    ]}
    />

    <section>
      <h2 className="l-container c-heading c-heading--h3 u-text--left">
        Other Awards
      </h2>
      <div className="l-container u-text--left">
        <p>
          <b className="c-heading--h5">2019</b>: Qualmark 100% Pure New Zealand Experience Awards – Finalist.
        </p>
      </div>
      <div className="l-container u-text--left">
        <p>
          <b className="c-heading--h5">2018</b>: Adventure Tourism Awards – NZ’s Best Accommodation Winner.
        </p>
      </div>
      <div className="l-container u-text--left">
        <p>
          <b className="c-heading--h5">2017</b>: Auckland Airport – $50,000 Tourism Development Grant Winner; Hoscar Awards – Top 3 Hostel in Oceania; Entrepreneurs Organisation NZ – ’Boldly Go’ Award.
        </p>
      </div>
      <div className="l-container u-text--left">
        <p>
          <b className="c-heading--h5">2016</b>: New Zealand International Business Awards
          – Best Emerging Business; New Zealand Innovation Awards – Marketing National Winner; Golden Backpack Awards – NZ’s Best Tour/Activity; Canterbury Business Awards – Best Large Tourism Operator; Rankers – Supreme Award Winner.
        </p>
      </div>
      <div className="l-container u-text--left">
        <p>
          <b className="c-heading--h5">2015</b>: TNT Golden Backpacker Awards
          – NZ’s Best Accommodation Brand; Rankers – Supreme Award Winner; New Zealand Tourism Awards – Business Excellence Award and Emerging Tourism Leader; Westpac Business Growth – Grant Winner; Auckland Business Awards – Excellence in Marketing.
        </p>
      </div>
      <div className="l-container u-text--left">
        <p>
          <b className="c-heading--h5">2014</b>: Canterbury Awards
          – Supreme Winner; HostelBookers – Best Accommodation in Oceania; TNT Golden Backpacker Awards – Best Tour/Activity; Rankers – Supreme Award Winner.
        </p>
      </div>
      <div className="l-container u-text--left">
        <p>
          <b className="c-heading--h5">2013</b>: Rankers Supreme Award Winner; Ernst and Young
          – Entrepreneur of the Year Award Finalist; HostelBookers – Best Staff Award (Haka Lodge Queenstown); Golden Backpack Awards Finalist;
          Canterbury Business Awards Finalist – Tourism, Events, Promotions and Hospitality; Tripadvisor Top 10% Award (Haka Lodge Christchurch).
        </p>
      </div>
      <div className="l-container u-text--left">
        <p>
          <b className="c-heading--h5">2012</b>: Rankers
          – Supreme Award Winner
        </p>
      </div>
      <div className="l-container u-text--left">
        <p>
          <b className="c-heading--h5">2011</b>: Rankers
          – NZ Wide Bus and Van Tour Winner
        </p>
      </div>
      <div className="l-container u-text--left">
        <p>
          <b className="c-heading--h5">2010</b>: New Zealand Tourism Awards
          – Young Entrepreneur of the Year
        </p>
      </div>
    </section>

    <section className="l-section" />

    {/* <BlogList */}
    {/* title="The Latest Haka News" */}
    {/* list={[1, 2]} */}
    {/* /> */}

  </Layout>
);

export default AboutAwardsPage;
